<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-09-10 09:43:36
 * @LastEditTime: 2019-09-29 14:49:19
 * @LastEditors: Please set LastEditors
 -->
<template>
  <div class="uplo">
<!--       
<el-upload class='image-uploader' :multiple='false' :auto-upload='true' list-type='text' :show-file-list='true'
	:before-upload="beforeUpload" :drag='true' action='' :limit="1" :on-exceed="handleExceed"
	:http-request="uploadFile">
	<i class="el-icon-upload"></i>
	<div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
	<div class="el-upload__tip" slot="tip">一次只能上传一个文件，仅限text格式，单文件不超过1MB</div>
</el-upload> -->
        <!-- <div @click="dian">111111</div> -->
        <div v-for="(i,index) in list" :key="index" @click="dian(index)">
            {{i.aa==2?i.text:''}}
        </div>
        <input type="text" @keyup="key" name="" id="" v-model="abc">
        <div id="aaa" @click="ceshia">2222222
        <transition name="div1">
            <div class="div2" v-show="dddd">1111</div>
        </transition>
        </div>
        <transition name="div3">
            <div class="div4" v-show="dddd">1111</div>
        </transition>
    </div>
</template>

<script>
import $ from 'jquery'
import axios from '../api/ajax.js'
import { import_dept, set_field_base } from '../api/api.js'
export default {
  name: 'uplo',
  data () {
    return {
      ent_id: 'ent001',
      abc: '',
      list: [
        {
          text: '1111',
          aa: '2'
        },
        {
          text: '222',
          aa: '1'
        },
        {
          text: '333',
          aa: '1'
        },
        {
          text: '444',
          aa: '1'
        },
        {
          text: '555',
          aa: '1'
        },
        {
          text: '666',
          aa: '1'
        },
        {
          text: '777',
          aa: '1'
        },
        {
          text: '888',
          aa: '1'
        }
      ],
      dddd: true
    }
  },
  created () {
    //   if (sessionStorage.getItem("pc_bianji_ent_id") != null&&sessionStorage.getItem("pc_bianji_ent_id") != undefined) {
    //     this.ent_id = JSON.parse(sessionStorage.getItem("pc_bianji_ent_id"));
    // }
  },
  mounted () {
  },
  methods: {
    dian (index) {
      this.list[index + 1].aa = '2'
    },
    key () {
      localStorage.setItem('ceshi', JSON.stringify(this.abc))
    },
    ceshia () {
      this.dddd = !this.dddd
      console.log(21111)
    }
    // // 上传文件之前的钩子
    //     beforeUpload(file) {
    //         //判断文件格式
    //         let hz = file.name.split(".")[1]
    //         if (hz != 'xlsx' && hz != 'xls') {
    //             return false;
    //         }else{
    //         }
    //         console.log(file.name.split(".")[1])
    //     },
    // // 上传文件个数超过定义的数量
    //         handleExceed(files, fileList) {
    //             console.log(files)
    //             this.$message.warning(`当前限制选择 1 个文件，请删除后继续上传`)
    //         },
    //     // 上传文件
    //         uploadFile(item) {
    //             let fileObj = item.file
    //             console.log(item)
    //             let form = new FormData()// FormData 对象
    //             form.append('files', fileObj)// 文件对象  'upload'是后台接收的参数名
    //             form.append('ent_id',this.ent_id);
    //             console.log(fileObj)
    //             $.ajax({
    //                 url:'https://www.mlcbr.com:8443/hwb_client/V1.0.0/import_prod_ai',
    //                 // url:'http://192.168.8.123:8080/hwb_client/V1.0.0/import_staff',
    //                 type: 'post',
    //                 data: form,
    //                 processData: false, //告诉jquery不要对form进行处理
    //                 contentType: false,
    //                 // headers:{
    //                 //     'Content-Type':'application/x-www-form-urlencoded',
    //                 // }		
    //             })
    //             // $.ajax({
    //             // 	url: "api/hwb_client/V1.0.0/import_dept",
    //             // 	data: form,
    //             // 	type: "POST",
    //             // 	contentType: "multipart/form-data",
    //             // 	processData: false, //告诉jquery不要对form进行处理
    //             // 	contentType: false, //指定为false才能形成正确的Content-Type
    //             // 	success: function (res) {
    //             //         console.log(res)
    //             //     }
    //             // })
    //             // import_dept({
    //             //     file:form,
    //             // }).then(res=>{
    //             //     console.log(res)
    //             // })
    //         },
    //     dian(){
    //     var aa=[
    //         {"field_name":"1","field_type":"2","field_level":"3","field_convert":"4","flag_add":"5"},
    //         {"field_name":"7","field_type":"8","field_level":"9","field_convert":"0","flag_add":"11"}
    //         ]
    //     aa=JSON.stringify(aa)
    //     console.log(aa)
    //     $.ajax({
    //         url:'https://www.mlcbr.com:8443/hwb_client/V1.0.0/set_field_base',
    //         type: 'post',
    //         data: aa,
    //         dataType: "json",
    //         contentType: 'application/json;charset=utf-8',
    //         async: true,	
    //     })
    // }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.uplo {
    height:100vh;
}
.div2{
    // height:1rem;
    // height:3rem;
    height:3rem;
    width:3rem;
    border-radius: 50%;
    background:yellow;
    transition-property:all;
    
}
.div1-enter-active{
    height:3rem;
    width:3rem;
    border-radius: 50%;
    transition: all .5s
}
.div1-enter/* .fade-leave-active in <2.1.8 */ {
    height:0;
    width:0;
}
.div1-leave{
    height:3rem;
    width:3rem;
    border-radius: 50%;
}
.div1-leave-active{
    height:0;
    width:0;
    transition: all .5s
}
@keyframes scaleDraw {
/*定义关键帧、scaleDrew是需要绑定到选择器的关键帧名称*/
    0% {
        border-radius: 50%;
        transform: rotate(0deg) translate3d(0, 0,0) scale(1) skew(10deg,60deg) rotateZ(-360deg) ;
    }
    20% {
        border-radius: 0;
        transform: rotate(72deg) translate3d(1rem, -1rem,0)  scale(1.2) ;
    }
    40% {
        border-radius: 50%;
        transform:rotate(144deg) translate3d(1rem, 0,0)  scale(1) skew(60deg,10deg);
    }
    60% {
        border-radius: 0;
        transform: rotate(216deg) translate3d(1rem, -1rem,0)  scale(1.2);
    }
    80% {
        border-radius: 50%;
        transform:rotate(288deg) translate3d(1rem, 0,0) scale(1) skew(10deg,60deg);
    }
    100% {
        border-radius: 0;
        transform:rotate(360deg) scale(1);
    }
}
    .div4 {
    width: 0.85rem;
    height: 0.85rem;
    box-shadow: 0 0 0.5rem rgb(250, 157, 157);
    -webkit-animation-name: scaleDraw; /*关键帧名称*/
    -webkit-animation-timing-function:linear; /*动画的速度曲线*/
    -webkit-animation-iteration-count: infinite; /*动画播放的次数*/
    -webkit-animation-duration: 4s; /*动画所花费的时间*/
    cursor: pointer;
    margin-left:4rem;
    }

</style>
